import {CommonModel} from "@/model/CommonModel";
import {getConfig} from "@/api/erp/ConfigApi";

/**
 * 配置model
 */
class ConfigModel {
  // 获取配置
  static async getConfig(key, field) {
    let [data] = await getConfig(key, field);
    return data.data;
  }
}

export {ConfigModel}
