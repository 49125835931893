<template>
  <div class="erp-uploader-one erp-uploader-one-pic" :id="'erp-uploader-one-pic-'+uploaderId">
    <el-upload
      action="dev"
      :show-file-list="false"
      :on-success="uploadSuccess"
      :http-request="upload=>uploadRequest(upload,uploadTarget)"
      :before-upload="beforeUpload">
      <img v-show="img&&!uploading" :src="img"
           class="img-show" @click="" alt="">
      <i v-if="!img&&!uploading" class="el-icon-plus uploader-icon"></i>
      <i v-if="!img&&uploading" class="el-icon-loading uploader-icon"></i>
    </el-upload>
    <div class="buttons flex flex-center" v-show="img">
      <el-button type="text"
                 @click="img?uploadPreviewShow=true:false">预览
      </el-button>
      <el-button type="text" @click="clickDeleteImg">删除</el-button>
    </div>
    <div class="des flex flex-dr flex-center" v-if="showDes">
      <div class="title">{{ uploaderTitle }}</div>
      <div>图片比例：{{ pixelLimit[0] }}px X {{ pixelLimit[1] }}px</div>
      <div>图片大小：{{ sizeLimit | sizeLimitFilter }}以内</div>
    </div>
    <el-dialog center v-el-drag-dialog :visible.sync="uploadPreviewShow" width="600px" append-to-body>
      <el-image width="100%" :src="img" alt="" style="cursor: pointer" lazy
              @click="window.open(img)"></el-image>
    </el-dialog>
  </div>
</template>

<script>
import {BaseUploadModel} from "@/model/BaseUploadModel";
import {msg_err} from "@/utils/ele_component";
import elDragDialog from "@/directive/el-drag-dialog";

/**
 * erp-单图片上传组件
 * @author edgar 2021.3.18
 * todo
 * 图片显示用七牛云切割显示
 */
export default {
  name: "erpUploaderOnePic",
  directives: {
    elDragDialog
  },
  filters: {
    // 图片大小过滤器
    sizeLimitFilter(v) {
      if (v >= 1024) {// 大于1M
        v = (v / 1024).toFixed(1)
        v = v.replace(/.0$/, '')
        return v + "MB"
      } else {
        return v + "KB"
      }
    },
  },
  props: {
    // 是否显示简介
    showDes: {
      type: Boolean,
      default: true
    },
    // 上传器 id
    uploaderId: {
      type: String,
      default: "",
    },
    // 上传器 显示分辨率
    uploaderSize: {
      type: Array,
      default: () => {
        return [100, 100]
      }
    },
    // 上传器 标题
    uploaderTitle: {
      type: String,
      default: ""
    },
    // 图片上传文件最大体积 KB
    sizeLimit: {
      type: Number,
      default: 1024
    },
    // 图片分辨率限制 PX
    pixelLimit: {
      type: Array,
      default: () => {
        return [200, 200]
      }
    },
    // 图片地址
    imgIn: {
      type: String,
      default: ""
    },
    // 要上传到的服务器
    uploadTarget: {
      type: String,
      default: "qiniu"
    },
  },
  computed: {
    img: {
      get: function () {
        return this.imgIn
      },
      set: function (newValue) {
        return newValue
      }
    }
  },
  data() {
    return {
      window: window,
      uploadRequest: BaseUploadModel.uploadRequest,
      uploading: false,
      uploadPreviewShow: false,
    }
  },
  mounted() {
    // 设置上传器宽高度和宽度
    document.querySelector("#erp-uploader-one-pic-" + this.uploaderId + " .el-upload").style.width = this.uploaderSize[0] + "px"
    document.querySelector("#erp-uploader-one-pic-" + this.uploaderId + " .el-upload").style.height = this.uploaderSize[1] + "px"
    if (!this.img) {
      document.querySelector("#erp-uploader-one-pic-" + this.uploaderId + " .uploader-icon").style.width = this.uploaderSize[0] + "px"
      document.querySelector("#erp-uploader-one-pic-" + this.uploaderId + " .uploader-icon").style.height = this.uploaderSize[1] + "px"
      document.querySelector("#erp-uploader-one-pic-" + this.uploaderId + " .uploader-icon").style.lineHeight = this.uploaderSize[1] + "px"
    }
    document.querySelector("#erp-uploader-one-pic-" + this.uploaderId + " .img-show").style.width = this.uploaderSize[0] + "px"
    document.querySelector("#erp-uploader-one-pic-" + this.uploaderId + " .img-show").style.height = this.uploaderSize[1] + "px"
  },
  updated() {
  },
  methods: {
    // 上传前检测
    beforeUpload(file) {
      if (!BaseUploadModel.fileTypeLimit(file, "image")) {
        msg_err('只能上传图片文件!')
        return false
      }
      const isLess = file.size / 1024 <= this.sizeLimit
      if (!isLess) {
        let v = this.sizeLimit
        let text = ""
        if (v >= 1024) {// 大于1M
          v = Math.ceil(v / 1024)
          text = v + "MB"
        } else {
          text = v + "KB"
        }
        msg_err('图片超过最大限制，最大' + text + "!")
        return false
      }
      this.uploading = true
      return true
    },
    // 上传成功后
    uploadSuccess(data) {
      this.$set(this, 'img', data.data)
      this.uploading = false
      this.$emit("uploadSuccess", [this.uploaderId, data.data])
      return true
    },
    // 点击删除图片
    clickDeleteImg() {
      this.img = ""
      // 检测是否有方法传入
      if (this.$listeners['afterDelete']) {
        this.$emit("afterDelete", [this.uploaderId])
      }
      // 设置上传加号图标css
      setTimeout(() => {
        document.querySelector("#erp-uploader-one-pic-" + this.uploaderId + " .uploader-icon").style.width = this.uploaderSize[0] + "px"
        document.querySelector("#erp-uploader-one-pic-" + this.uploaderId + " .uploader-icon").style.height = this.uploaderSize[1] + "px"
        document.querySelector("#erp-uploader-one-pic-" + this.uploaderId + " .uploader-icon").style.lineHeight = this.uploaderSize[1] + "px"
      }, 100)

    }
  }
}
</script>

<style scoped lang="less">
.buttons {
  text-align: center;
}

.des .title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}
</style>
