import {request_async} from "@/utils/requestAsync";
import {API_URL_ERP} from "@/model/ConfigModel";

// 获取配置信息
export async function getConfig(key, field) {
  return request_async(API_URL_ERP + `v1/config/config`, "get", {
    key, field
  });
}


