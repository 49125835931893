import {editOfficialWebConfig} from "@/api/exp/ConfigApi";

/**
 * 配置model
 */
class ExpConfigModel {

    // 修改官网配置
    static async editOfficialWebConfig(info) {
        let [data] = await editOfficialWebConfig(info);
        if (data.code === 20000) {
            return true;
        } else {
            return false;
        }
    }
}

export {ExpConfigModel}
