// 操作Vue-Element-Admin框架


class VEAModel {
  // 通用底部悬浮按钮组-跟随app-container改变width
  static bottomButtonContainerWidthChange() {
    let $=window.$
    let maxWidth = $(".app-main").width()
    $(".bottom-button-container").css("width", maxWidth)
    // 监听窗口变化
    $(window).resize(function () {
      let maxWidth = $(".app-main").width()
      $(".bottom-button-container").css("width", maxWidth)
    });
  }
}

export {VEAModel}
